
import { pagination } from "../../../../utils/constants";
export const fetchVessel = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const url = searchParams ? "/vessel" : "/vessel";
      return url;
    },
  });
};
export const fetchVesselbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/vessel/${id}`;
      return url;
    },
  });
};

export const addVessel = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel`,
      method: "post",
      body: data,
    }),
  });
export const updateVessel = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const deleteVessel = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `vessel/${id}`,
      method: "DELETE",
    }),
  });

export const addVesselAdditionalDetails = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-additional-details`,
      method: "post",
      body: data,
    }),
  });

export const fetchVesselAdditionalDetailsbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/vessel-additional-details/${id}`;
      return url;
    },
  });
};
export const updateVesselAdditionalDetail = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel-additional-details/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const initiatePreapprovalProcess = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/pre-approval-process`,
      method: "post",
      body: data,
    }),
  });
export const fetchSurveyApplication = (build) => {
  return build.query({
    query: ({ searchParams = "", page = "" }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/survey-applications/list?${params.join("&")}`;
      return url;
    },
  });
};
export const addPreApprovalProcess = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `pre-approval-process/submit/${id}`,
      method: "POST",
    }),
  });

export const deleteVesselAdditionalDetails = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `vessel-additional-details/${id}`,
      method: "DELETE",
    }),
  });

export const fetchPreApprovalProcess = (build) => {
  return build.query({
    query: ({ searchParams = "" }) => {
      const params = [];
      if (searchParams) params.push(searchParams);

      const url = `/pre-approval-process?${params.join("&")}`;
      return url;
    },
  });
};
