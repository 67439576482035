import React, { useEffect, useState, useReducer, lazy } from "react";
import Sidemenu from "../components/sideBar/sidebar";
import Navbar from "../components/sideBar/navbar";
import { AdminRoutes } from "./AdminRoutes";
import { PcRoutes } from "./PcRoutes";
import { SurveyorRoutes } from "./SurveyorRoutes";
import { NavalArchitectRoutes } from "./NavalArchitectRoutes";
import { RARoutes } from "./RARoutes";
import { Outlet } from "react-router-dom";
import { UserRoutes } from "./UserRoutes";
import UserService from "../services/UserService";
import { VesselOwnerRoutes } from "./VesselOwnerRoutes";
import { BuilderRoutes } from "./BuilderRoutes";
import { YardRoutes } from "./YardRoutes";
import { useLazyFetchLoggedUserQuery } from "../services/modules/admin/users";
import Loader from "../components/Loader";
import Registration from "../pages/VesselOwner/Registration";
import AddBuilder from "../pages/AdminModule/Builder/AddBuilder";
import AddYard from "../pages/AdminModule/Yard/AddYard";
import { DataEntryOperatorRoutes } from "./DataEntryOperatorRoutes";
import { DataEntryApproverRoutes } from "./DataEntryApproverRoutes";

function PrivateRoutes({ children }) {
  const [routes, setRoutes] = useState("");
  const [registrationNeeded, setRegistrationNeeded] = useState(false);
  const [roleCode, setRoleCode] = useState("");
  const [userSelectedRole, setUserSelectedRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fetchLoggedUser, { data: userData }] = useLazyFetchLoggedUserQuery();

  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserProfile();
      if (userDetail) {
        if (userDetail?.attributes?.role?.at(0) == "Vessel Owner") {
          setUserSelectedRole("vessel-owner");
        } else if (userDetail?.attributes?.role?.at(0) == "Builder") {
          setUserSelectedRole("builder");
        } else if (userDetail?.attributes?.role?.at(0) == "Yard Owner") {
          setUserSelectedRole("yard-owner");
        }
      }
    };
    getUserInfo();
  }, []);
  useEffect(() => {
    if (UserService.hasRole(["vessel-owner", "builder", "yard-owner"])) {
      fetchLoggedUser({});
    }
  }, []);

  useEffect(() => {
    if (roleCode.length > 0) {
      if (roleCode == "vessel-owner") {
        if (userData?.result && userData.result.length > 0) {
          const userDetails = JSON.stringify(userData?.result[0]);
          localStorage.setItem("userDetails", userDetails);
        }
      } else {
        localStorage.removeItem("userDetails");
      }
    }
  }, [roleCode, userData]);

  useEffect(() => {
    if (userData) {
      if (userData?.result?.length === 0) {
        setRegistrationNeeded(true);
      } else if (userData?.result[0]) {
        setRoleCode(userData.result[0].role?.roleCode || "");
      }
      setIsLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (roleCode.length > 0) {
      setRegistrationNeeded(false);
    }
  }, [roleCode]);

  const handleRouteChange = (roleCode) => {
    setRoleCode(roleCode);
    fetchLoggedUser({});
  };

  useEffect(() => {
    if (roleCode) {
      switch (roleCode) {
        case "admin":
          setRoutes(AdminRoutes);
          break;
        case "vessel-owner":
          setRoutes(VesselOwnerRoutes);
          break;
        case "yard-owner":
          setRoutes(YardRoutes);
          break;
        case "port-conservator":
          setRoutes(PcRoutes);
          break;
        case "registering-authority":
          setRoutes(RARoutes);
          break;
        case "naval-architect":
          setRoutes(NavalArchitectRoutes);
          break;
        case "surveyor":
          setRoutes(SurveyorRoutes);
          break;
        case "builder":
          setRoutes(BuilderRoutes);
          break;
        case "dataentry-operator":
          setRoutes(DataEntryOperatorRoutes);
          break;
          case "data-approver":
          setRoutes(DataEntryApproverRoutes);
          break;
        default:
          break;
      }
    }
  }, [roleCode]);

  const getValidate = ({ children }) => {
    if (UserService.isLoggedIn()) {
      return children;
    }
    return null;
  };
  const handleBuilderRouteChange = () => {
    setRegistrationNeeded(false);
    setRoutes(BuilderRoutes);
    fetchLoggedUser({});
    // setRoleCode(roleCode);
  };

  const handleYardRouteChange = () => {
    setRegistrationNeeded(false);
    setRoutes(YardRoutes);
    fetchLoggedUser({});
  };

  return (
    <div id="root">
      {registrationNeeded == true && !isLoading && (
        <div>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <div className="layout-page ms-0">
                {/* <Navbar /> */}
                {userSelectedRole == "vessel-owner" ? (
                  <Registration
                    crumbs="Registration"
                    changeRoutes={handleRouteChange}
                  />
                ) : userSelectedRole == "builder" ? (
                  <AddBuilder
                    crumbs="Builder/Add"
                    changeRoutes={handleBuilderRouteChange}
                    registrationNeeded={true}
                  />
                ) : (
                  <AddYard
                    crumbs="Yard/Add"
                    changeRoutes={handleYardRouteChange}
                    registrationNeeded={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {registrationNeeded == false && !isLoading && (
        <div>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Sidemenu routes={routes} />
              <div className="layout-page">
                <Navbar />
                <Outlet />
                {getValidate({ children })}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
}

export default PrivateRoutes;
