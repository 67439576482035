export const ACTIVE = "active";
export const ADMIN_DASHBOARD = "Admin";
export const ADMIN = "ADMIN";

export const VESSEL_OWNER = "vessel-owner";
export const BUILDER = "BUILDER";
export const YARD = "YARD";

export const VO_DASHBOARD = "VO Dashboard";
export const VO_PRE_APPROVAL = "Pre-Approval";
export const VO_SURVEY_VIEW = "Survey Application";
export const VO_DECLARATION_VIEW = "Declaration Form";
export const VO_PROVISIONAL_CERTIFICATE = "Provisional Certificate";
export const VO_CERTIFICATE_SURVEY = "Certificate of Survey";
export const VO_REGISTRATION_PROVISIONAL_CERTIFICATE =
  "Provisional Certificate";
export const VO_REGISTRATION_OWNERSHIP_DECLARATION = "Declaration of Ownership";

export const SURVEYOR = "Surveyor";
export const NAVAL_ARCHITECTURE = "Naval Architecture";
export const PORT_CONSERVATOR = "Port Conservator";

export const DATA_ENTRY_OPERATOR = "Data Entry Operator";
export const DATA_ENTRY_APPROVER = "Data Entry Approver";

export const RA = "Registering Authority";
export const MY_PROFILE = "My Profile";

export const SURVEY_APPLIACTION_BREADCRUM1 = "Survey";
export const SURVEY_APPLIACTION_BREADCRUM2 = "Survey Application";
export const SURVEY_APPLIACTION_BREADCRUM3 = "New Application";
export const SURVEY_APPLIACTION_PAGETITLE = "New Application";

export const DECLARATION_FORM_BREADCRUM2 = "Declaration of Survey";
export const DECLARATION_FORM_PAGETITLE = "Declaration of Survey";

export const PROVISIONAL_CERTIFICATE_BREADCRUM2 = "Provisional Certificate";
export const PROVISIONAL_CERTIFICATE_PAGETITLE =
  "Provisional Certificate of Survey";

export const REGISTRATION_BREADCRUM1 = "Registration";
export const REGISTRATION_PROVISIONAL_CERTIFICATE_BREADCRUM2 =
  "Certificate of Provisional Certificate";
export const REGISTRATION_PROVISIONAL_CERTIFICATE_PAGETITLE =
  "Provisional Certificate of Registration";

export const REGISTRATION_OWNERSHIP_DECLARATION_BREADCRUM2 =
  "Declaration of Ownership";
export const REGISTRATION_OWNERSHIP_DECLARATION_PAGETITLE =
  "Declaration of Ownership";

export const VO_REGISTRATION_VIEW = "Registration Application";
export const VO_REGISTRATION_INSPECTION_VIEW = "Inspection";

export const VO_CARVING_MAKING_NOTE_VIEW = "Carving & Making Note";

export const VO_REGISTRATION_OF_ALTERATION = "Registration of alteration";

export const VO_CERTIFICATE_OF_REGISTRATION = "Certificate of Registration";

export const VO_TRANSFER_OF_REGISRTY = "Transfer of Registry";

export const ShowMessage = {
  title: "Register Successfully Completed",
  description: "Please login into your account and continue.",
  buttonText: "Login",
};

export const VESSEL_CATEGORY_CODE = {
  A: "CATEGORY-A",
  B: "CATEGORY-B",
  C: "CATEGORY-C",
};
export const VESSEL_TYPE_CODE = {
  HouseBoat: "HOUSE-BOAT",
  Barge: "BARGE",
  Cargo: "CARGO-VESSEL",
  Dredger: "DREDGER",
  Ferry: "FERRY",
  Tug: "TUG",
  Shikkara: "SHIKKARA",
  PassengerVessel: "PASSENGER-VESSEL",
  MotorBoatInboardEngine: "MOTOR-BOAT-INBOARD-ENGINE",
  MotorBoatOutboardEngine: "MOTOR-BOAT-OUTBOARD-ENGINE",
};
export const pagination = {
  limit: 10,
};
export const sideEngineOptions = [
  { label: "Port Side", value: "portSide" },
  { label: "Starboard Side", value: "starboardSide" },
  { label: "Not Applicable", value: "notApplicable" },
];

export const sideEngineLabel = [
  { key: "portSide", label: "Port Side" },
  { key: "starboardSide", label: "Starboard Side" },
  { key: "notApplicable", label: "Not Applicable" },
];

export const options = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
];
export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const taxApplicableOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const nameofManufactureinputFields = [
  {
    label: "Soda Acid",
    id: "soda_acide",
    name: "soda_acide",
    type: "text",
    placeholder: "Enter",
  },
  {
    label: "Foam",
    id: "foam",
    name: "foam",
    type: "text",
    placeholder: "Enter",
  },
  {
    label: "Dry Powder",
    id: "dry_powder",
    name: "dry_powder",
    type: "text",
    placeholder: "Enter",
  },
  {
    label: "Any other types",
    id: "any_other_text",
    name: "any_other_text",
    type: "text",
    placeholder: "Enter",
  },
];

export const pollutionControlinputFields = [
  {
    label: "Sewage treatment and disposal",
    id: "sewage_treatment",
    name: "sewage_treatment",
    type: "text",
    required: true,
    placeholder: "Enter",
  },
  {
    label: "Solid waste processing and disposal",
    id: "solid_waste_processing",
    name: "solid_waste_processing",
    type: "text",
    required: true,
    placeholder: "Enter",
  },
  {
    label: "Sound Pollution Control",
    id: "sound_polution_control",
    name: "sound_polution_control",
    type: "text",
    required: true,
    placeholder: "Enter",
  },
  {
    label: "Water Consumption/Day",
    id: "water_consumption_day",
    name: "water_consumption_day",
    type: "text",
    required: true,
    placeholder: "Enter",
  },
  {
    label: "Source of Water",
    id: "source_of_water",
    name: "source_of_water",
    type: "text",
    required: true,
    placeholder: "Enter",
  },
];

export const statusOption = [
  // survey
  { value: "submitted", label: "Submitted" },
  { value: "under_processing", label: "Under Processing" },
  { value: "Approved", label: "Approved" },
  { value: "Draft", label: "Draft" },
  { value: "Rejected", label: "Rejected" },
  { value: "not_Applied", label: "Not Applied" },
  { value: "issued", label: "Issued" },
  { value: "Pending", label: "Pending" },
];

export const downloadFile = (url) => {
  console.log(url);
  // const urls = `http://localhost:3000/${url}`
  // const fileName=urls.split("/").pop();
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "downloads");
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export const listEmptyMessage = "No data available for preview at the moment!";
export const ConfirmDeletion = "Confirm Deletion";

export const deckValue = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const numVal = [];
for (let i = 1; i <= 10; i++) {
  numVal.push({ value: i, label: i.toString() });
}

export const stateValue = [
  { value: "Kerala", label: "Kerala" },
  // {value:"Tamil Nadu",label:"Tamil Nadu"},
  // {value:"Karnataka",label:"Karnataka"},
];

export const soundSignals = [
  { value: "Mechanical", label: "Mechanical" },
  { value: "Electrical", label: "Electrical" },
];

export const nozzlesType = [
  { value: "Jet type", label: "Jet type" },
  { value: "Spary type", label: "Spary type" },
  { value: "Jet-Spray type", label: "Jet-Spray type" },
];
export const fileUploadTypes = {
  RULE: "RULE",
  AFFIDAVIT: "AFFIDAVIT",
  ADDRESS_PROOF: "ADDRESS_PROOF",
  ACCREDITATION: "ACCREDITATION",
  PRE_APPROVAL: "PRE_APPROVAL",
  INSURANCE: "INSURANCE",
  CERT_SURVEY: "CERT_SURVEY",
  CERT_REGISTRATION: "CERT_REGISTRATION",
  CERT_INSURANCE: "CERT_INSURANCE",
  CARVING: "CARVING",
};
export const processType = {
  PRE_APPROVAL: "preapproval",
  SURVEY: "survey",
  REGISTRATION: "registration",
  CARVING: "carving",
};

export const fileCategories = {
  generalArrangementPlan: {
    label: "General arrangement plan",
    name: "generalArrangementPlan",
  },
  linesPlanOffsetTable: {
    label: "Lines Plan and Offset Table",
    name: "linesPlanOffsetTable",
  },
  structuralDrawings: {
    label: "Structural Drawings",
    name: "structuralDrawings",
  },
  midshipDrawings: {
    label: "Midship Drawings",
    name: "midshipDrawings",
  },
  deckAreaCalculation: {
    label: "Deck Area Calculation",
    name: "deckAreaCalculation",
  },
  freeboardMarkings: {
    label: "Freeboard Markings",
    name: "freeboardMarkings",
  },
  shellExpansion: {
    label: "Shell Expansion",
    name: "shellExpansion",
  },
  machineryLayout: {
    label: "Machinery Layout",
    name: "machineryLayout",
  },
  propellerDrawings: {
    label: "Propeller Drawings",
    name: "propellerDrawings",
  },

  shaftingSternGear: {
    label: "Shafting and stern gear",
    name: "shaftingSternGear",
  },
  steeringPlan: {
    label: "Steering Plan",
    name: "steeringPlan",
  },
  pipingArrangement: {
    label: "Piping Arrangement (Fresh water, Black water, Grey water) Drawings",
    name: "pipingArrangement",
  },
  bilgeWaterSystemPlan: {
    label: "Bilge water system plan",
    name: "bilgeWaterSystemPlan",
  },
  accommodationPlan: {
    label: "Accommodation plan including deck area calculation plan",
    name: "accommodationPlan",
  },
  fuelOiltransferEngineCooling: {
    label: "Fuel Oil transfer and Engine cooling system plan",
    name: "fuelOiltransferEngineCooling",
  },
  electricalDrawing: {
    label: "Electrical drawing",
    name: "electricalDrawing",
  },
  ventilationPlan: {
    label: "Ventilation Plan",
    name: "ventilationPlan",
  },
  lifeSavingPlan: {
    label: "Life saving appliances plan",
    name: "lifeSavingPlan",
  },
  fireFightingPlan: {
    label: "Fire Fighting appliances plan",
    name: "fireFightingPlan",
  },
  navigationalPlan: {
    label: "Lights, Sound, Navigational and communication equipment plan",
    name: "navigationalPlan",
  },
  compartmentSubdivision: {
    label: "Compartment subdivision and Flooding calculation",
    name: "compartmentSubdivision",
  },
  linesPlan: {
    label: "Lines Plan",
    name: "linesPlan",
  },
  draftLinesPlan: {
    label: "Draft lines plan",
    name: "draftLinesPlan",
  },
  lifeSavingPlanandFireFightingPlan: {
    label: "Life Saving Appliances plan & Fire Fighting Appliances Plan",
    name: "lifeSavingPlanandFireFightingPlan",
  },
  preliminaryStability: {
    label: "Preliminary Stability",
    name: "preliminaryStability",
  },
  damageStability: {
    label: "Damage Stability",
    name: "damageStability",
  },
};

export const licenseRuleOptions = [
  { value: " IV Act ", label: " IV Act " },
  { value: "KIV Rule", label: "KIV Rule" },
];

export const cocGrade = [
  { value: "Lascar", label: "Lascar" },
  { value: "Srang", label: "Srang" },
  { value: " Second class master", label: " Second class master" },
  { value: "First class master", label: "First class master" },
  { value: "second engine driver", label: "second engine driver" },
  { value: "first engine driver", label: "first engine driver" },
  { value: " Engineer", label: " Engineer" },
];

export const stageVerificationTypes = {
  KEELLAYING: "Keel laying",
  LAUNCHING: "Launching",
  STABILITY: "Stability",
  ADDITIONAL_REQUEST: "Additional Request",
};

export const vesselHistoryTypes = {
  PRE_APPROVAL: "pre_approval",
  STAGE_VERIFICATION: "stage_verification",
  SURVEY: "survey",
  REGISTRATION: "registration",
  CARVING: "carving",
};

export const chargeListType = {
  new: "NEWVESSEL",
  alteration: "ALTERATION",
  keelLaying: "KEELLAYING",
  launching: "LAUNCHING",
  stability: "STABILITY",
  initialSurvey: "INITIALSURVEY",
  renewalSurvey: "RENEWALSURVEY",
  annualSurvey: "ANNUALSURVEY",
  additionalSurvey: "ADDITIONALSURVEY",
  dockingSurvey: "DOCKINGSURVEY",
  registration: "REGISTRATION",
  carving: "CARVING",
};

export const rateCategory = [
  { value: "new vessel", label: "new vessel" },
  { value: "alteration", label: "alteration" },
  { value: "initial survey", label: " initial survey" },
  { value: "renewal survey", label: "renewal survey" },
  { value: "annual survey", label: "annual survey" },
  { value: "additional survey", label: "additional survey" },
  { value: "docking survey", label: "docking survey" },
  { value: "registration", label: "registration" },
  { value: "carving", label: "carving" },
];

export const ownershipTypes = {
  individual: "INDIVIDUAL",
  cooperative: "COOPERATIVE",
  company: "COMPANY",
  partnership: "PARTNERSHIP",
};

export const choice = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const typeOfOwnership = [
  {
    label: "Individual",
    value: ownershipTypes.individual,
  },
  {
    label: "Co-Operative Society",
    value: ownershipTypes.cooperative,
  },
  {
    label: "Company",
    value: ownershipTypes.company,
  },
  {
    label: "Partnership Firm/Trust/Societies",
    value: ownershipTypes.partnership,
  },
];
