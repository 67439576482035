import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  addRate,
  deleteRate,
  fetchRate,
  fetchRateById,
  fetchCategory,
  updateRate,
  fetchRateByCategory,
  fetchRateByApplicationId,
} from "./rates";

export const RateAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addRate: addRate(build),
    fetchRate: fetchRate(build),
    fetchRateById: fetchRateById(build),
    fetchRateByCategory: fetchRateByCategory(build),
    fetchCategory: fetchCategory(build),
    fetchRateByApplicationId: fetchRateByApplicationId(build),
    updateRate: updateRate(build),
    deleteRate: deleteRate(build),
  }),
  overrideExisting: false,
});

export const {
  useAddRateMutation,
  useLazyFetchRateQuery,
  useLazyFetchRateByIdQuery,
  useLazyFetchRateByCategoryQuery,
  useLazyFetchRateByApplicationIdQuery,
  useLazyFetchCategoryQuery,
  useUpdateRateMutation,
  useDeleteRateMutation,
} = RateAPI;
