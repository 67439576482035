import { lazy } from "react";
import {
  faDashboard,
  faCircleCheck,
  faClipboardList,
  faUserPen,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import { ACTIVE, vesselHistoryTypes } from "../utils/constants";
import CarvingDetails from "../pages/Carving/Request/CarvingDetails";
import CarvingList from "../pages/Carving/List/CarvingList";

// import SurveyRequestList from "../pages/Survey/List/SurveyRequestList";
// import SurveyRequestDetails from "../pages/Survey/Request/SurveyRequestDetails";

const PreApprovalList = lazy(() =>
  import("../pages/PreApproval/List/PreApprovalList")
);
const PreApprovalDetails = lazy(() =>
  import("../pages/PreApproval/Request/PreApprovalRequestDetails")
);
const SurveyRequestList = lazy(() =>
  import("../pages/Survey/List/SurveyRequestList")
);
const SurveyRequestDetails = lazy(() =>
  import("../pages/Survey/Request/SurveyRequestDetails")
);
const RegistrationList = lazy(() =>
  import("../pages/Registration/List/RegistrationList")
);
const RegistrationDetails = lazy(() =>
  import("../pages/Registration/Request/RegistrationDetails")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));

const VesselHistory = lazy(() =>
  import("../pages/VesselHistory/VesselHistory")
);

const DocumentVerification = lazy(() =>
  import("../components/DocumentVerification")
);

const UserVesselList = lazy(() => import("../pages/Vessels/List"));
const UserVesselDetails = lazy(() =>
  import("../pages/Vessels/Request/VesselDetails")
);
const CrewList = lazy(() => import("../pages/CrewManagement/List/CrewList"));
const CrewDetails = lazy(() =>
  import("../pages/CrewManagement/List/CrewDetails")
);

export const PcRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    component: <Dashboard crumbs="Dashboard" />,
  },

  {
    name: "Vessel Requests",
    icon: faCircleCheck,
    path: "/vessel-requests",
    component: <PreApprovalList crumbs="Vessel/Vessel Requests" />,
    mainMenu: true,
    childPaths: ["/vessel-request-details", "/vessel-requests/vessel-history"],
  },
  {
    name: "Vessel Request Details",
    component: (
      <PreApprovalDetails crumbs="Vessel Requests/Vessel Request Details" />
    ),
    path: "/vessel-request-details",
  },

  {
    name: "Survey",
    icon: faClipboardList,
    path: "/survey-request",
    component: <SurveyRequestList crumbs="Survey/Survey Requests" />,
    mainMenu: true,
    childPaths: ["/survey-request-details", "/survey-request/vessel-history"],
  },
  {
    name: "Survey Request Details",
    component: (
      <SurveyRequestDetails crumbs="Survey Request/ #Reference Number" />
    ),
    path: "/survey-request-details",
  },
  // {
  //   name: "Registration",
  //   icon: faUserPen,
  //   path: "/registration-requests",
  //   component: <RegistrationList crumbs="Registration/Registration Requests" />,
  //   mainMenu: true,
  //   childPaths: [
  //     "/registration-request-details",
  //     "/registration-requests/vessel-history",
  //   ],
  // },
  {
    name: "Pre-registration",
    icon: faUserPen,
    path: "/registration-requests",
    component: (
      <RegistrationList crumbs="Pre-registration/Pre-registration Requests" />
    ),
    // mainMenu: true,
    subMenuHead: true,
    childrens: [
      {
        name: "Registration",
        icon: faClipboardList,
        path: "/carving-requests",
        component: <CarvingList crumbs="Registration/Registration Requests" />,

        childPaths: [
          "/carving-request-details",
          "/carving-requests/vessel-history",
        ],
      },
    ],
    childPaths: [
      "/registration-request-details",
      "/registration-requests/vessel-history",
    ],
  },
  // {
  //   name: "Carving",
  //   icon: faClipboardList,
  //   component: <CarvingList crumbs="Vessel Requests/Vessel Request Details" />,
  //   path: "/carving-requests",
  //   mainMenu: true,
  // },
  {
    name: "Registration Request Details",
    component: (
      <RegistrationDetails crumbs="Pre-registration Request/Registration Requests Details" />
    ),
    path: "/registration-request-details",
  },
  {
    name: "Vessel History",
    path: "/vessel-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Vessel Requests / Vessel History"
        type={vesselHistoryTypes.PRE_APPROVAL}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/survey-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Survey / Vessel History"
        type={vesselHistoryTypes.SURVEY}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/registration-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Pre-registration / Vessel History"
        type={vesselHistoryTypes.REGISTRATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/carving-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Registration / Vessel History"
        type={vesselHistoryTypes.CARVING}
      />
    ),
  },
  {
    name: "Owner Documents",
    component: (
      <DocumentVerification crumbs="Vessel Requests/Vessel Request Details" />
    ),
    path: "/vessel-request-details/owner-documents",
  },
  {
    name: "Owner Documents Survey",
    component: (
      <DocumentVerification crumbs="Survey Requests/Survey Request Details" />
    ),
    path: "/survey-request-details/owner-documents",
  },
  {
    name: "Owner Documents Pre-registration",
    component: (
      <DocumentVerification crumbs="Pre-registration Request/Registration Requests Details" />
    ),
    path: "/registration-request-details/owner-documents",
  },
  {
    name: "Owner Documents Registration",
    component: (
      <DocumentVerification crumbs="Registration/Registration Requests" />
    ),
    path: "/carving-request-details/owner-documents",
  },
  {
    name: "Owner Documents Vessel Details",
    component: (
      <DocumentVerification crumbs="/vessels/vessels-details" />
    ),
    path: "/vessels/vessels-details/owner-documents",
  },
  {
    name: "Vessels",
    component: <UserVesselList crumbs="Vessels" adminVesselList={false} />,
    path: "/vessels",
    mainMenu: true,
    icon: faShip,
    childPaths: ["/vessels/vessel-history", "/vessels/vessels-details"],
  },

  {
    name: "Vessel History",
    path: "/vessels/vessel-history",
    component: <VesselHistory crumbs="Vessels / Vessel History" type={null} />,
  },
  {
    name: "Vessel Details",
    path: "/vessels/vessels-details",
    component: <UserVesselDetails crumbs="/vessels/vessels-details" />,
  },

  {
    name: "Crew List",
    icon: faClipboardList,
    path: "/crew-list",
    component: <CrewList crumbs="Crew/Crew Requests" />,
    mainMenu: true,
  },
  {
    name: "Crew Details",
    component: <CrewDetails crumbs="Crew Requests/Crew Requests Details" />,
    path: "/crew-details",
  },
];
