import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchState,
  fetchStateById,
  updateState,
  addState,
  deleteState,
} from "./State";

export const StateDetailAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchState: fetchState(build),
    fetchStateById: fetchStateById(build),
    updateState: updateState(build),
    addState: addState(build),
    deleteState: deleteState(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchStateQuery,
  useLazyFetchStateByIdQuery,
  useUpdateStateMutation,
  useAddStateMutation,
  useDeleteStateMutation,
} = StateDetailAPI;
