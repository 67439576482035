import { Accordion, Col, Row } from "react-bootstrap";
import {
  useLazyFetchVesselAdditionalDetailsbyIdQuery,
  useLazyFetchVesselbyIdQuery,
} from "../../services/modules/vesselowner/preapproval";
import { Fragment, useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { Config } from "../../config";
import { useLazyFetchUserbyIdQuery } from "../../services/modules/admin/users";
import { useLazyFetchVesselBuilderbyIdQuery } from "../../services/modules/admin/vessel";
import { useLazyFetchYardbyIdQuery } from "../../services/modules/admin/yard";

function VesselDetails({
  vesselId = null,
  setVesselData = () => {},
  setAdditionalData = () => {},
}) {
  const [fetchVesselbyId, { data: vesselData, isSuccess }] =
    useLazyFetchVesselbyIdQuery();

  const [
    fetchVesselAdditionalDetailsbyId,
    { data: vesselAdditionalData, isSuccess: isVesselAdditionalSuccess },
  ] = useLazyFetchVesselAdditionalDetailsbyIdQuery();

  const [vesselAdditionalDetailId, setVesselAdditionalDetailId] =
    useState(null);

  const [
    fetchVesselBuilderbyId,
    { data: vesselBuilderData, isSuccess: isFetchVesselBuilderSuccess },
  ] = useLazyFetchVesselBuilderbyIdQuery();

  const [fetchYardbyId, { data: yardData, isSuccess: isFetchYardSuccess }] =
    useLazyFetchYardbyIdQuery();

  useEffect(() => {
    if (!isFetchVesselBuilderSuccess) return;

    const builderData = vesselBuilderData?.result?.at(0);

    const { builderName, permanentAddress, city, district, state, pinCode } =
      builderData;

    const builderDetails = {
      builderName,
      permanentAddress,
      city,
      districtName: district?.districtName ?? "",
      stateName: state?.stateName ?? "",
      pinCode,
    };

    setValuesData((prev) => {
      return {
        ...prev,
        builderDetails: { ...prev.builderDetails, ...builderDetails },
      };
    });
  }, [isFetchVesselBuilderSuccess, vesselBuilderData]);

  useEffect(() => {
    if (!isFetchYardSuccess) return;

    const { yardName } = yardData?.result;

    setValuesData((prev) => {
      return {
        ...prev,
        builderDetails: { ...prev.builderDetails, yardName: yardName },
      };
    });
  }, [isFetchYardSuccess, yardData]);

  const [ownerId, setOwnerId] = useState(null);
  const [
    fetchUserbyId,
    { data: userDataById, isSuccess: isUserDataByIdSuccess },
  ] = useLazyFetchUserbyIdQuery();

  useEffect(() => {
    if (!ownerId) return;
    fetchUserbyId({ id: ownerId });
  }, [ownerId, fetchUserbyId]);

  useEffect(() => {
    if (!isUserDataByIdSuccess) return;
    const data = userDataById.result[0];
    const { firstName, houseName, city, district, pinCode } = data;
    const ownerDetails = {
      ownerName: firstName,
      ownerHouseNo: houseName,
      ownerCity: city,
      ownerDistrict: district?.districtName,
      ownerState: "Kerala", // TODO: Use dynamic data when owner state is implemented in backend
      ownerPincode: pinCode,
    };
    setValuesData((prev) => {
      return {
        ...prev,
        ownerDetails: { ...prev.ownerDetails, ...ownerDetails },
      };
    });
  }, [userDataById, isUserDataByIdSuccess]);

  useEffect(() => {
    if (!vesselId) return;
    fetchVesselbyId({ id: vesselId });
  }, [fetchVesselbyId, vesselId]);

  useEffect(() => {
    if (!isSuccess) return;
    const data = vesselData.result[0];
    if (!data) return;

    setVesselData(data);

    const {
      requestType,
      district,
      portRegOfVessel,
      port,
      vesselName,
      vesselCategory,
      vesselType,
      vesselSubType,
      ownerId,
      builderId,
      yardId,
    } = data;

    if (builderId) fetchVesselBuilderbyId({ id: builderId });
    if (yardId) fetchYardbyId({ id: yardId });

    if (ownerId) setOwnerId(ownerId);

    const ownerDetails = {
      requestType: requestType?.preApprovalType || "",
      district: district?.districtName || "",
      portRegOfVessel: portRegOfVessel?.name || "",
      port: port?.portName || "",
      vesselName: vesselName || "",
      vesselCategory: vesselCategory?.vesselCategoryName || "",
      vesselType: vesselType?.vesselTypeName || "",
      vesselSubType: vesselSubType?.vesselSubTypeName || "",
    };
    setValuesData((prev) => {
      return { ...prev, ownerDetails };
    });

    setVesselAdditionalDetailId(data.vesselAdditionalDetail._id);
  }, [vesselData, isSuccess, setVesselData]);

  useEffect(() => {
    if (!vesselAdditionalDetailId) return;
    fetchVesselAdditionalDetailsbyId({ id: vesselAdditionalDetailId });
  }, [fetchVesselAdditionalDetailsbyId, vesselAdditionalDetailId]);

  useEffect(() => {
    if (!isVesselAdditionalSuccess) return;
    const data = vesselAdditionalData.result;
    setAdditionalData(data);

    const {
      hulls,
      propulsionOfEngines,
      equipment,
      fireAppliance,
      fireExtinguisher,
      pollutionControlDevices,
      communicationEquipment,

      passengerCapasity,
      lengthOverall,
      lengthOnDeck,
      breadth,
      depth,
    } = data;

    const builderDetails = {
      materialOfHull: hulls.materialOfHull,
      freeboarddeck: hulls.freeboarddeck,
      passengerCapasity: passengerCapasity,
      lengthOverall: lengthOverall,
      lengthOnDeck: lengthOnDeck,
      breadth: breadth,
      depth: depth,
    };

    const particularsOfHulls = { bulkHeads: hulls.BulkHeads };

    const particularsOfPropulsionOfEngines = {
      propulsionOfEngines: propulsionOfEngines,
    };
    const particularsOfEquipments = {
      anchorPortWeight: equipment.anchorPort.weight,
      anchorPortMaterial: equipment.anchorPort.material,
      starboardWeight: equipment.starboard.weight,
      starboardMaterial: equipment.starboard.material,
      anchorSpareWeight: equipment.anchorSpare.weight,
      anchorSpareMaterial: equipment.anchorSpare.material,
      portChainSize: equipment.portChain.size,
      portChainType: equipment.portChain.type,
      portChainLength: equipment.portChain.length,
      starboardChainSize: equipment.starboard.size,
      starboardChainType: equipment.starboard.type,
      starboardChainLength: equipment.starboard.length,
      ropesNumber: equipment.ropes.number,
      ropesSize: equipment.ropes.size,
      ropesMaterial: equipment.ropes.material,
      searchLightsNumber: equipment.searchLights.number,
      searchLightsSize: equipment.searchLights.size,
      searchLightsPowder: equipment.searchLights.number,
      lifeBuoys: equipment.lifebuoys,
      buoyantLanyard: equipment.buoyantLanyard,
      navigationLights: equipment.navigationLights,
      soundSignals: equipment.soundSignals,
    };
    const particularsOfFireAppliance = {
      fireMains: fireAppliance.fireMains,
      firePumps: fireAppliance.firePumps,
      numberOfHydrants: fireAppliance.numberOfHydrants,
      noOfHose: fireAppliance.noOfHose,
      nozzles: fireAppliance.nozzles,
      otherEquipment: fireAppliance.otherEquipment,
    };
    const additionalInformations = {
      fireExtinguisherNumber: fireExtinguisher.number,
      sodaAcid: fireExtinguisher.sodaAcid,
      foam: fireExtinguisher.foam,
      dryPowder: fireExtinguisher.dryPowder,
      otherType: fireExtinguisher.otherType,
      communicationEquipment: communicationEquipment,
      particularsOfPollutionControlDevices: pollutionControlDevices.particulars,
      sewageTreatment: pollutionControlDevices.sewageTreatment,
      soundPollutionCntrl: pollutionControlDevices.soundPollutionCntrl,
      solidWaste: pollutionControlDevices.solidWaste,
      waterConsumption: pollutionControlDevices.waterConsumption,
      sourceOfWater: pollutionControlDevices.sourceOfWater,
    };

    setValuesData((prev) => {
      return {
        ...prev,
        particularsOfHulls,
        builderDetails: { ...prev.builderDetails, ...builderDetails },
        particularsOfPropulsionOfEngines,
        particularsOfEquipments,
        particularsOfFireAppliance,
        additionalInformations,
      };
    });
  }, [vesselAdditionalData, isVesselAdditionalSuccess]);

  const [valuesData, setValuesData] = useState({
    ownerDetails: {},
    builderDetails: {},
    particularsOfHulls: {},
    particularsOfPropulsionOfEngines: {},
    particularsOfEquipments: {},
    particularsOfFireAppliance: {},
    additionalInformations: {},
    files: {},
  });

  const dataFields = [
    {
      field: "ownerDetails",
      heading: "Owner Details",
      data: [
        { title: "Request type", value: "requestType" },
        { title: "District", value: "district" },
        { title: "Port of Registry", value: "portRegOfVessel" },
        { title: "Port", value: "port" },
        { title: "Owner Name", value: "ownerName" },
        { title: "Owner House Name/No.", value: "ownerHouseNo" },
        { title: "Owner City", value: "ownerCity" },
        { title: "Owner District", value: "ownerDistrict" },
        { title: "Owner State", value: "ownerState" },
        { title: "Owner Pincode", value: "ownerPincode" },
        { title: "Name of Vessel", value: "vesselName" },
        { title: "Category of Vessel", value: "vesselCategory" },
        { title: "Type of Vessel", value: "vesselType" },
        { title: "Sub Type of Vessel", value: "vesselSubType" },
      ],
    },
    {
      field: "builderDetails",
      heading: "Particulars of Hulls",
      data: [
        { title: "Builder's Name", value: "builderName" },
        { title: "Builder's Address", value: "permanentAddress" },
        { title: "Builder's City", value: "city" },
        { title: "Builder's District", value: "districtName" },
        { title: "Builder's State", value: "stateName" },
        { title: "Builder's Pincode", value: "pinCode" },
        { title: "Material of the hull", value: "materialOfHull" },
        {
          title: "Whether with a deck above free board deck",
          value: "freeboarddeck",
        },
        {
          title: "Passenger Capacity",
          value: "passengerCapasity",
        },
        { title: "Length Overall", value: "lengthOverall" },
        { title: "Length on Deck", value: "lengthOnDeck" },
        { title: "Breadth", value: "breadth" },
        { title: "Depth", value: "depth" },
        { title: "Yard", value: "yardName" },
      ],
    },
    {
      field: "particularsOfHulls",
      heading: "",
      subHeading: "Bulk Heads",
      type: "tableArray",
      value: "bulkHeads",
      data: [
        { title: "Bulk Head Placement", value: "placementOfBulkHeads" },
        { title: "Bulk Head Thickness", value: "thicknessOfBulkHeads" },
        { title: "Hull Plating Material", value: "hullPlatingMaterial" },
        { title: "Hull Plating Thickness", value: "hullPlatingThickness" },
      ],
    },
    {
      field: "particularsOfPropulsionOfEngines",
      heading: "Particulars of Propulsion of Engines",
      subHeading: "Engine",
      type: "sectionArray",
      value: "propulsionOfEngines",
      data: [
        { title: "Number of sets with BHP of each", value: "noOfSets" },
        { title: "Manufacturers Name/Brand", value: "manufacturerNameOrBrand" },
        { title: "Model Number", value: "modelNo" },
        { title: "Type of Engine", value: "typeOfEngine" },
        {
          title: "Diameter of propulsion shaft",
          value: "diameterOfPropulsionShaft",
        },
        {
          title: "Material of propulsion shaft",
          value: "materialOfPropulsionShaft",
        },
        { title: "Number of gears", value: "noOfGears" },
        { title: "Type of gears", value: "typeOfGears" },
      ],
    },
    {
      field: "particularsOfEquipments",
      heading: "Particulars of Equipments",
      data: [
        { title: "Anchor-Port weight", value: "anchorPortWeight" },
        { title: "Anchor-Port Material", value: "anchorPortMaterial" },
        { title: "Starboard weight", value: "starboardWeight" },
        { title: "Starboard Material", value: "starboardMaterial" },
        { title: "Anchor-Spare weight", value: "anchorSpareWeight" },
        { title: "Anchor-Spare Material", value: "anchorSpareMaterial" },
        { title: "Port-Chain Size", value: "portChainSize" },
        { title: "Port-Chain Type", value: "portChainType" },
        { title: "Port-Chain Length", value: "portChainLength" },
        { title: "Starboard-Chain Size", value: "starboardChainSize" },
        { title: "Starboard-Chain Type", value: "starboardChainType" },
        { title: "Starboard-Chain Length", value: "starboardChainLength" },
        { title: "Ropes Size", value: "ropesSize" },
        { title: "Ropes Material", value: "ropesMaterial" },
        { title: "No of Ropes", value: "ropesNumber" },
        { title: "Search Lights Number", value: "searchLightsNumber" },
        { title: "Search Lights Size", value: "searchLightsSize" },
        { title: "Search Lights Power", value: "searchLightsPowder" },
        { title: "Life buoys", value: "lifeBuoys" },
        {
          title: "Buoyant apparatus with self ignited light/ buoyant lanyard",
          value: "buoyantLanyard",
        },
        {
          title: "Navigation Lights giving particulars",
          value: "navigationLights",
        },
        { title: "Sound Signals", value: "soundSignals" },
      ],
    },
    {
      field: "particularsOfFireAppliance",
      heading: "Particulars of Fire Appliance",
      firePumpsData: [
        { title: "Size of Fire Pumps", value: "size" },
        { title: "Capacity of Fire Pumps", value: "capasity" },
      ],
      fireMainsData: [
        { title: "Fire Pumps Diameter", value: "diameter" },
        { title: "Fire Pumps Material", value: "maerial" },
      ],
      data: [
        { title: "Number of Hydrants", value: "numberOfHydrants" },
        { title: "Number of Hose", value: "noOfHose" },
        { title: "Nozzles", value: "nozzles" },
        { title: "Any other equipments", value: "otherEquipment" },
      ],
    },
    {
      field: "additionalInformations",
      heading: "Additional Informations",
      data: [
        {
          title: "No of portable fire extinguisher with particulars",
          value: "fireExtinguisherNumber",
        },
        { title: "Soda Acid", value: "sodaAcid" },
        { title: "Foam", value: "foam" },
        { title: "Dry Powder", value: "dryPowder" },
        { title: "Any other types", value: "otherType" },
        {
          title: "Particulars of communication equipments",
          value: "communicationEquipment",
        },
        {
          title: "Particulars of pollution control devices",
          value: "particularsOfPollutionControlDevices",
        },
        { title: "Sewage treatment and disposal", value: "sewageTreatment" },
        { title: "Solid waste processing and disposal", value: "solidWaste" },
        { title: "Sound pollution control", value: "soundPollutionCntrl" },
        { title: "Water consumption/day", value: "waterConsumption" },
        { title: "Source of water", value: "sourceOfWater" },
      ],
    },
  ];

  const handleDownloadClick = (data) => {
    const baseUrl = Config.HOST_URL;
    window.open(`${baseUrl}/uploads/${data}`, "_blank");
  };

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>Vessel Application</Accordion.Header>
      <Accordion.Body>
        {dataFields?.map(
          ({
            field,
            heading,
            subHeading,
            data,
            firePumpsData,
            fireMainsData,
            type,
            value,
          }) => {
            return (
              <div>
                <h4 className="my-3">{heading}</h4>
                {type === "tableArray" ? (
                  <TableArrayField
                    subHeading={subHeading}
                    fields={data}
                    values={valuesData[field][value]}
                  />
                ) : type === "sectionArray" ? (
                  <SectionArrayField
                    subHeading={subHeading}
                    fields={data}
                    values={valuesData[field][value]}
                  />
                ) : field === "particularsOfFireAppliance" ? (
                  <Row>
                    {+valuesData?.[field]?.["noofFirePumps"] > 0 ? (
                      <TableArrayField
                        subHeading="Fire Pumps"
                        fields={firePumpsData}
                        values={valuesData[field]["firePumps"]}
                        col={6}
                      />
                    ) : null}
                    {+valuesData?.[field]?.["noOffireMains"] > 0 ? (
                      <TableArrayField
                        subHeading="Fire Mains"
                        fields={fireMainsData}
                        values={valuesData[field]["fireMains"]}
                        col={6}
                      />
                    ) : null}
                    <DataFields data={data} values={valuesData[field]} />
                  </Row>
                ) : (
                  <Row>
                    <DataFields data={data} values={valuesData[field]} />
                  </Row>
                )}
              </div>
            );
          }
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}

function DataFields({ data = [], values = {} }) {
  return (
    <>
      {data?.map((item, key) => {
        return (
          <Col key={key} md={4} className="mb-2">
            <p className="field ">{item.title}</p>
            <p className="display-field fw-bold">{values[item.value]}</p>
          </Col>
        );
      })}
    </>
  );
}

function TableArrayField({
  fields = [],
  values = [],
  subHeading = "",
  col = 3,
}) {
  return (
    <Row>
      <h5>{subHeading}</h5>
      {values?.map((item, key) => (
        <Fragment key={key}>
          <Col md={2} className="d-flex justify-content-end align-items-center">
            {key + 1}
          </Col>
          <Col md={10}>
            <Row>
              {fields?.map(({ title, value }, index) => {
                return (
                  <Col key={index} md={col}>
                    <p className="field ">{title}</p>
                    <p className="display-field fw-bold">{item[value]}</p>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Fragment>
      ))}
    </Row>
  );
}

function SectionArrayField({ fields = [], values = [], subHeading = "" }) {
  return (
    <>
      {values?.map((item, key) => (
        <Row key={key}>
          <Row>
            <h5>
              {subHeading} {key + 1}
            </h5>
          </Row>
          <Row>
            {fields?.map(({ title, value }, index) => {
              return (
                <Col key={index} md={3}>
                  <p className="field ">{title}</p>
                  <p className="display-field fw-bold">{item[value]}</p>
                </Col>
              );
            })}
          </Row>
        </Row>
      ))}
    </>
  );
}

function DownloadButton({ ...props }) {
  return (
    <button
      style={{
        border: "none",
        backgroundColor: "transparent",
        outline: "none",
        color: "#3D5CB8",
      }}
      className="d-flex align-items-center justify-content-center gap-2"
      {...props}
    >
      <AiOutlineDownload />
      <span>Download</span>
    </button>
  );
}
export default VesselDetails;
