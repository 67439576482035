import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import SelectBox from "../../../components/Selectbox";
import TextBox from "../../../components/TextBox";
import { MdDelete } from "react-icons/md";
import { numVal, sideEngineOptions } from "../../../utils/constants";
import { AiOutlineDownload } from "react-icons/ai";
import { useLazyDownloadFileQuery } from "../../../services/modules/common/file";

const PropulsionEngine = ({ vesselDataAdditional, propulsionEngine }) => {
  const initialValues = {
    purchaseBill: "",
    propulsionOfEngines: [
      {
        noOfSets: "",
        manufacturerNameOrBrand: "",
        modelNo: "",
        typeOfEngine: "",
        diameterOfPropulsionShaft: "",
        materialOfPropulsionShaft: "",
        noOfGears: "",
        typeOfGears: "",

        files: {
          engineCertificate: "",
          testCertificate: "",
          builderCertificate: "",
        },
      },
    ],
  };

  useEffect(() => {
    console.log("propulsion engine", propulsionEngine);
    if (!propulsionEngine) return;

    formik.setValues({
      purchaseBill: vesselDataAdditional?.purchaseBill,
      propulsionOfEngines: propulsionEngine.map((engine) => ({
        noOfSets: engine?.noOfSets || "",
        manufacturerNameOrBrand: engine?.manufacturerNameOrBrand || "",
        modelNo: engine?.modelNo || "",
        typeOfEngine: engine?.typeOfEngine || "",
        diameterOfPropulsionShaft: engine?.diameterOfPropulsionShaft || "",
        materialOfPropulsionShaft: engine?.materialOfPropulsionShaft || "",
        noOfGears: engine?.noOfGears || "",
        typeOfGears: engine?.typeOfGears || "",
        files: {
          engineCertificate: engine?.files?.engineCertificate || "",
          testCertificate: engine?.files?.testCertificate || "",
          builderCertificate: engine?.files?.builderCertificate || "",
        },
        sideOfEngine: engine?.sideOfEngine || "",
      })),
    });
  }, [propulsionEngine]);

  const fileUploadFields = [
    {
      name: "engineCertificate",
      label: "Engine Certificate",
    },
    {
      name: "testCertificate",
      label: "Test Certificate",
    },

    {
      name: "builderCertificate",
      label: "Builder Certificate",
    },
  ];

  const [downloadFile, { data }] = useLazyDownloadFileQuery();

  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const formik = useFormik({
    initialValues,
    // validationSchema,
    // onSubmit,
  });

  const { values, errors, onBlur, touched, handleChange, setFieldValue } =
    formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        {/* <p className="fw-bold" style={{ marginTop: "10px" }}>
          LSA/FSA Purchase Bill
        </p> */}
        <Row>
          <Col md={7}>
            <div
              className="d-flex flex-column gap-4 card p-3"
              style={{ width: "340px" }}
            >
              <div className="fw-bold d-flex gap-2">
                <p className="fw-bold" style={{ paddingTop: "14px" }}>
                  LSA/FSA Purchase Bill
                </p>
                <button
                  className="d-flex align-items-center  gap-2 border-0 bg-transparent outline-none btn_back_text"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownloadClick(formik.values?.purchaseBill);
                  }}
                >
                  <AiOutlineDownload />
                  <span>Download</span>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <FieldArray name="propulsionOfEngines">
          {({ remove, push }) => (
            <>
              <div className="d-flex flex-column gap-2 propulsion-engine">
                {values?.propulsionOfEngines?.map((value, index) => (
                  <>
                    <div className="card p-3 mt-5">
                      <Row className="pb-3" key={index}>
                        <Row className="d-flex justify-items-between">
                          <Col md={11}>
                            <h2>Engine {index + 1}</h2>
                          </Col>
                        </Row>
                        <Col md={4}>
                          <SelectBox
                            label="Number of sets with BHP of each"
                            name={`propulsionOfEngines[${index}].noOfSets`}
                            onChange={handleChange}
                            value={value?.noOfSets}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.noOfSets
                            }
                            isTouched={
                              touched?.propulsionOfEngines?.at(index)?.noOfSets
                            }
                            onBlur={onBlur}
                            options={numVal}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Manufacturers Name/Brand"
                            name={`propulsionOfEngines[${index}].manufacturerNameOrBrand`}
                            id="manufacturerNameOrBrand"
                            type="text"
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.manufacturerNameOrBrand}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.manufacturerNameOrBrand
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.manufacturerNameOrBrand
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Model Number/Engine Serial Number"
                            name={`propulsionOfEngines[${index}].modelNo`}
                            type="text"
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value.modelNo}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.modelNo
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)?.modelNo
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Type of Engine"
                            name={`propulsionOfEngines[${index}].typeOfEngine`}
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.typeOfEngine}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.typeOfEngine
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.typeOfEngine
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Diameter of propulsion shaft"
                            name={`propulsionOfEngines[${index}].diameterOfPropulsionShaft`}
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.diameterOfPropulsionShaft}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.diameterOfPropulsionShaft
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.diameterOfPropulsionShaft
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Material of propulsion shaft"
                            name={`propulsionOfEngines[${index}].materialOfPropulsionShaft`}
                            type="text"
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.materialOfPropulsionShaft}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.materialOfPropulsionShaft
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.materialOfPropulsionShaft
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectBox
                            label="No of gears"
                            name={`propulsionOfEngines[${index}].noOfGears`}
                            onChange={handleChange}
                            value={value?.noOfGears}
                            error={
                              errors?.propulsionOfEngines?.at(index)?.noOfGears
                            }
                            isTouched={
                              touched?.propulsionOfEngines?.at(index)?.noOfGears
                            }
                            onBlur={onBlur}
                            options={numVal}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <TextBox
                            label="Type of gears"
                            name={`propulsionOfEngines[${index}].typeOfGears`}
                            placeholder="Enter"
                            onChange={handleChange}
                            value={value?.typeOfGears}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.typeOfGears
                            }
                            touched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.typeOfGears
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectBox
                            label="Side of Engine"
                            name={`propulsionOfEngines[${index}].sideOfEngine`}
                            placeholder="Enter"
                            options={sideEngineOptions}
                            onChange={handleChange}
                            value={value?.sideOfEngine}
                            isRequired={true}
                            error={
                              errors?.propulsionOfEngines?.at(index)
                                ?.sideOfEngine
                            }
                            isTouched={
                              touched?.propulsionOfEngines?.at(index)
                                ?.sideOfEngine
                            }
                            onBlur={onBlur}
                            isDisabled={true}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="card p-3">
                      <h2 style={{ paddingBottom: "10px" }}>File Upload</h2>
                      <Row>
                        {fileUploadFields?.map(({ name, label }, key) => (
                          <Col md={3}>
                            {formik.values?.propulsionOfEngines?.at(index)
                              ?.files?.[name] ? (
                              <>
                                <div className="fw-bold">
                                  <p className="fw-bold">{label}</p>
                                  <button
                                    className="d-flex align-items-center justify-content-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDownloadClick(
                                        formik.values?.propulsionOfEngines[
                                          index
                                        ]?.files[name]
                                      );
                                    }}
                                  >
                                    <AiOutlineDownload />
                                    <span>Download</span>
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </form>
  );
};

export default PropulsionEngine;
