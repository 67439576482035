import Keycloak from "keycloak-js";
import "./styles.scss";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_CONFIG_URL || 'https://auth.kiv-dev.ults.build',
  realm: process.env.REACT_APP_KEYCLOAK_CONFIG_REALM || 'kmb-main',
  clientId: process.env.REACT_APP_KEYCLOAK_CONFIG_CLIENT_ID || 'frontend',
  /* url: "http://localhost:8080/",
  realm: "kiv-dev",
  clientId: "playground", */
});

export default keycloak;
