import { Accordion, FormControl, Row } from "react-bootstrap";
import { useLazyFetchRegistrationByIdQuery } from "../../services/modules/registration";
import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import { AiOutlineDownload } from "react-icons/ai";
import { useLazyDownloadFileQuery } from "../../services/modules/common/file";

function RegistrationDetails({ registrationId = null }) {
  const [fetchRegistrationById, { data, isSuccess }] =
    useLazyFetchRegistrationByIdQuery();

  const [downloadFile] = useLazyDownloadFileQuery();

  useEffect(() => {
    if (!registrationId) return;
    fetchRegistrationById({ id: registrationId });
  }, [fetchRegistrationById, registrationId]);

  const rowHead = [
    { heading: "Sl.no", md: 1 },
    { heading: "Insurance Name", md: 3 },
    { heading: "Insurance Number", md: 3 },
    { heading: "Insurance Validity", md: 3 },
    { heading: "Insurance Document", md: 2 },
  ];

  const fieldNames = [
    { field: "name" },
    { field: "number" },
    { field: "validity" },
    { field: "fileName", type: "button" },
  ];

  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header>Registration Application</Accordion.Header>
      <Accordion.Body>
        <div className="card my-3">
          <div className="card-body">
            <h4 className="my-3">Insurance Details</h4>
            <Row>
              <TableHead rowHead={rowHead} />
              <TableBody
                fieldNames={fieldNames}
                data={isSuccess ? data?.result?.insuranceDto : []}
              />
            </Row>
          </div>
        </div>

        {data?.result?.additionalDetails &&
          data?.result?.additionalDetails.length > 0 && (
            <div className="card my-3">
              <div className="card-body">
                <h4 className="my-3">Additional Details</h4>
                <Row>
                  <Col md={1}>
                    <p className="field">Sl no</p>
                  </Col>
                  <Col md={3}>
                    <p className="field">Document Name</p>
                  </Col>
                  <Col md={1}>
                    <p className="field">File</p>
                  </Col>
                </Row>

                {data?.result?.additionalDetails?.map((additional, index) => (
                  <Row key={index}>
                    <Col md={1}>
                      <p className=" fw-bold">{index + 1}</p>
                    </Col>
                    <Col md={3}>
                      <FormControl
                        className="fw-bold"
                        type="text"
                        value={additional?.name}
                      />
                    </Col>
                    <Col md={1}>
                      <p className="fw-bold">
                        <button
                          className="d-flex align-items-center justify-content-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                          onClick={() => {
                            handleDownloadClick(additional?.fileName);
                          }}
                        >
                          <AiOutlineDownload />
                          <span>Download</span>
                        </button>
                      </p>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          )}

        <div className="card my-3">
          <div className="card-body">
            <h4 className="my-3">Pollution Certificate</h4>
            <Row>
              <Col md={1}>
                <p className="field">Sl no</p>
              </Col>
              <Col md={3}>
                <p className="field">File</p>
              </Col>
            </Row>

            {data?.result?.pollutionCertificate?.map((pollution, index) => (
              <Row key={index}>
                <Col md={1}>
                  <p className=" fw-bold">{index + 1}</p>
                </Col>
                <Col md={1}>
                  <p className="fw-bold">
                    <button
                      className="d-flex align-items-center justify-content-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                      onClick={() => {
                        handleDownloadClick(pollution.fileName);
                      }}
                    >
                      <AiOutlineDownload />
                      <span>Download</span>
                    </button>
                  </p>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function TableHead({ rowHead = [] }) {
  return (
    <Row>
      {rowHead.map(({ heading, md }, key) => (
        <Col md={md} key={key}>
          <p className="field">{heading}</p>
        </Col>
      ))}
    </Row>
  );
}

function TableBody({ data = [] }) {
  const [downloadFile] = useLazyDownloadFileQuery();

  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <>
      {data.map((value, index) => (
        <Row key={index}>
          <Col md={1}>
            <p className=" fw-bold">{index + 1}</p>
          </Col>
          <Col md={3}>
            <FormControl className="fw-bold" type="text" value={value.name} />
          </Col>
          <Col md={3}>
            <FormControl className="fw-bold" type="text" value={value.number} />
          </Col>
          <Col md={3}>
            <FormControl
              className="fw-bold"
              type="text"
              value={value.validity}
            />
          </Col>
          <Col md={1}>
            {" "}
            <p className="fw-bold">
              <button
                className="d-flex align-items-center justify-content-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                onClick={() => {
                  handleDownloadClick(value.fileName);
                }}
              >
                <AiOutlineDownload />
                <span>Download</span>
              </button>
            </p>
          </Col>
        </Row>
      ))}
    </>
  );
}

export default RegistrationDetails;
