import React from "react";
import { ACTIVE } from "../utils/constants.js";
import { faDashboard } from "@fortawesome/free-solid-svg-icons";

const AddYard = React.lazy(() => import("../pages/AdminModule/Yard/AddYard"));
const ViewYard = React.lazy(() =>
  import("../pages/AdminModule/Yard/ViewYard.js")
);

const ListYardType = React.lazy(() =>
  import("../pages/AdminModule/Yard/index.js")
);
const Dashboard = React.lazy(() => import("../pages/Dashboard"));

export const YardRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    subMenuHead: false,
    component: <Dashboard crumbs="Dashboard" />,
    
  },
  // {
  //   name: "YARD",
  //   component: <ListYardType crumbs="Yard" />,
  //   //  role: builder,
  //   mainMenu: true,
  //   path: "/list-yard",
  // },
  // {
  //   name: "ADD_YARD",
  //   component: <AddYard crumbs="Yard/Add" />,
  //   path: "/add-yard",
  // },
  // {
  //   name: "VIEW_YARD",
  //   component: <ViewYard crumbs="Yard/View" />,
  //   path: "/view-yard",
  // },
  // {
  //   name: "LIST_YARD",
  //   component: <ListYardType crumbs="Yard/List" />,
  //   path: "/list-yard",
  // },
];
